<style lang="scss" scoped>
.custom-footer {
  width: 1920px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 5;
  .bg {
    position: absolute;
    bottom: 0;
    height: 37px;
    width: 100%;
    background-image: url("../../assets/footer.png");
  }
  
  .buttons {
    position: absolute;
    bottom: 52px;
    left: 404px;
    .button {
      pointer-events: all;
      cursor: pointer;
      width: 116px;
      height: 40px;
      background: rgba(45, 57, 87, 0.3);
      border: 1px solid rgba(255, 255, 255, 0.3);
      backdrop-filter: blur(7px);
      /* Note: backdrop-filter has minimal browser support */
      cursor: pointer;
      border-radius: 8px;
      margin-bottom: 12px;
      color: white;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-right: 7px;
      }

      &:hover {
        background: linear-gradient(
          180deg,
          rgba(86, 94, 159, 0.3) 0%,
          rgba(50, 53, 98, 0.5) 108%
        );
        border: 1px solid rgba(255, 255, 255, 0.3);
        box-shadow: inset 0px 0px 12px 4px rgba(88, 91, 181, 0.83);
      }

      &#active {
        background: linear-gradient(180deg, #565e9f 0%, #323562 108%);
        border: 1px solid rgba(255, 255, 255, 0.5);
        box-shadow: inset 0px 0px 12px 4px rgba(88, 91, 181, 0.83);
      }
    }
  }

  #error{
    // left: 1120px!important;
    
  }
}

.disable {
  pointer-events: all;
  cursor: pointer;
  width: 116px;
  height: 40px;
  background: rgba(45, 57, 87, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(7px);
  /* Note: backdrop-filter has minimal browser support */
  cursor: pointer;
  border-radius: 8px;
  margin-bottom: 12px;
  color: rgb(255, 255, 255, 0.1);
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    margin-right: 7px;
  }

  border-radius: 8px !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  background: rgba(45, 57, 87, 0.3) !important;
  backdrop-filter: blur(7px) !important;
  &:hover {
    border-radius: 8px !important;
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
    background: rgba(45, 57, 87, 0.3) !important;
    backdrop-filter: blur(7px) !important;
  }
}
</style>

<template>
  <div class="custom-footer">
    <div class="bg"></div>
    <div class="buttons" :id="$route.name">
      <!-- <div
        class="button"
        v-if="$store.state.isInner != ''"
        id="active"
        @click="returnscene"
      >
        <img src="../../assets/return.png" alt="" />
        返回场景
      </div>
      <div
        class="button"
        v-else
        :id="$store.state.sceneNow == 'all' ? 'active' : ''"
        @click="setscene('all')"
      >
        <img src="../../assets/earth.png" alt="" />

        初始场景
      </div> -->
          <div
        class="disable"
      >
        <img style="opacity:0.1" src="../../assets/earth.png" alt="" />

        初始场景
      </div> 
      <div
        class="disable"
        :id="$store.state.sceneNow == 'ground' ? 'active' : ''"
      >
        地面
      </div>
      <!-- <div
        class="button"
        :id="$store.state.sceneNow == 'b1' ? 'active' : ''"
        @click="setscene('b1')"
      >
        BI
      </div> -->
         <div
        class="button"
        id="active"
      >
        BI
      </div>
      <div class="disable" :id="$store.state.sceneNow == 'b2' ? 'active' : ''">
        B2
      </div>
    </div>
  </div>
</template>

<script>
import bus from "@/lib/bus";
export default {
  name: "custom-footer",
  watch: {
    //场景发送改变执行的逻辑
    "$store.state.sceneNow"() {
      bus.$emit("floorSelection", this.$store.state.sceneNow);
    },
  },
  methods: {
    setscene(scene) {
      if (this.$store.state.sceneNow == scene) {
        return;
      }

      bus.$emit("set-state-dialogThree", false);
      this.$store.state.sceneNow = scene;
    },
    returnscene() {
      this.setscene("all");
      bus.$emit('closeAllSprite')
          bus.$emit("sceneRunning1",false)
    bus.$emit("sceneRunning2",false)
      this.$store.state.isInner = "";
    },
  },
};
</script>
