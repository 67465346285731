<style lang="scss" scoped>
.custom-head {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  width: 1920px;
  height: 100px;
  background-image: url("../../assets/head.png");
  .buttons {
    position: absolute;
    top: 40px;
    left: 469px;
    display: flex;
    .button-c {
      width: 162px;
    }
    .button {
      width: 186px;
      height: 39px;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: all;
      // transform: translateX(-50px);
      background-size: 100% 100%;
      background-image: url("../../assets/button1.png");
      cursor: pointer;
      &:hover {
        background-image: url("../../assets/button2.png");
      }

      &#active {
        background-image: url("../../assets/button3.png");
      }
      span {
        background: linear-gradient(180deg, #ffffff 60.16%, #8790f1 88.55%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        font-size: 20px;
        font-family: "pm";
      }
    }
    .button:nth-child(1) {
    }
  }
  .times {
    display: flex;
    height: 39px;
    position: absolute;
    right: 23.5px;
    top: 40px;
    align-items: center;
    font-size: 12px;
    color: white;
  }
}
</style>

<template>
  <div class="custom-head">
    <div class="buttons">
      <div class="button-c" :key="index" v-for="(item, index) of buttons">
        <div
          class="button"
          :id="$route.name == item.routerName ? 'active' : ''"
          @click="setRouter(item.routerName)"
        >
          <span>{{ item.name }}</span>
        </div>
      </div>
    </div>
    <div class="times">
      <span style="margin-right:16px">无锡</span>
      <img
        src="../../assets/snoy.png"
        width="16"
        height="16"
        style="margin-right:5px;margin-top:3px"
        alt=""
      />
      <span style="margin-right:16px">小雨</span>
      <span style="margin-right:16px">7~16℃</span>
      <span style="margin-right:16px">{{ date }}</span>
      <span style="margin-right:24px">{{ time }}</span>
      <span style="margin-right:2px">退出</span>
      <img src="../../assets/exit.png" width="12" alt="" />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import bus from "./../../lib/bus";
export default {
  name: "custom-head",
  data() {
    return {
      buttons: [
        {
          name: "环境监测",
          routerName: "enviroment",
        },
        {
          name: "能耗管理",
          routerName: "energy",
        },
        {
          name: "设备运维",
          routerName: "device",
        },
        {
          name: "停车管理",
          routerName: "parking",
        },
        {
          name: "告警中心",
          routerName: "error",
        },
        {
          name: "监控中心",
          routerName: "monitor",
        },
      ],
      date: "",
      time: "",
    };
  },
  methods: {
    setRouter(name) {
      this.$router.push({ name });

      bus.$emit("camera-anima", name);

      bus.$emit("isShow-energt-model", name);
    },
    getTime() {
      this.time = moment().format("hh:mm:ss");
      this.date = moment().format("YYYY-MM-DD");
      setTimeout(() => {
        this.getTime();
      }, 1000);
    },
  },
  created() {
    this.getTime();
  },
};
</script>
