// const console = {
//   log: () => { }
// }

import store from "../../store";
import router from "../../router"
const fn = (runScene, inputData = {}, constant = {}) => {
  const fn = (map) => {
    const {
      runScene,
      Utils,
      store,
      core,
      getModel,
      constant,
      bus,
      Three,
      camera,
      scene,
      controls,
      renderer,
    } = map;

    const {
      pointB2,
      fengjiB2,
      zhaoming,
      wangguan,
      chongdianzhuang,
      changdijiankong,
      daozhajiankong,
      daozhashebei,
      dianzitinchepai,
      huanjingzhiliang,
      bianpeidian,
      warningArray,
      standbyArray,
      gaojing
    } = constant;

    // 所有的看板
    const spriteDom = [
      ...fengjiB2,
      ...zhaoming,
      ...wangguan,
      ...chongdianzhuang,
      ...changdijiankong,
      ...daozhajiankong,
      ...daozhashebei,
      ...dianzitinchepai,
      ...huanjingzhiliang,
      ...bianpeidian,
      ...gaojing
    ];

    // 场景初始化
    class InitScene {
      name = "initScene";
      mounted() {
        // 脚本
        runScene.script.playAll();
        // 入场动画
        runScene.cameraEx.setTemp("2楼", { time: 2 });
      }
    }

    class Camera {
      name = "camera";
      cameraMap = {
        enviroment: '环境监测',
        energy: '能耗管理',
        device: '设备运维',
        parking: '停车管理',
        error: '告警中心',
        monitor: '监控中心',
      }
      mounted() {
        bus.$on('camera-anima', this.cameraAnima.bind(this))
      }
      cameraAnima(name) {
        if (!this.cameraMap[name]) return
        const n = this.cameraMap[name];
        runScene.cameraEx.setTemp(n, { time: 2 })
      }
    }

    // 添加点位
    class Point {
      name = "point";

      pointMap = {
        // floor1: {},
        floor2: { name: "点位_1", point: {} },
        // floor3: {},
      };

      // 车位 映射表
      cheweiMap = {}

      // 看板映射表
      spriteDomMap = {
        warning: { warning: {}, normal: {}, standby: {} },
        fengjiB2: { warning: {}, normal: {}, standby: {} },
        zhaoming: { warning: {}, normal: {}, standby: {} },
        wangguan: { warning: {}, normal: {}, standby: {} },
        chongdianzhuang: { warning: {}, normal: {}, standby: {} },
        changdijiankong: { warning: {}, normal: {}, standby: {} },
        daozhajiankong: { warning: {}, normal: {}, standby: {} },
        daozhashebei: { warning: {}, normal: {}, standby: {} },
        dianzitinchepai: { warning: {}, normal: {}, standby: {} },
        huanjingzhiliang: { warning: {}, normal: {}, standby: {} },
        bianpeidian: { warning: {}, normal: {}, standby: {} },
      }

      // 接口名称映射表
      webNameMap = {
        // 告警
        'warning': "warning",
        // 风机
        'fengjiB2': "fengjiB2",
        // 变配电
        'bianpeidian': 'bianpeidian',
        // 照明
        'zhaoming': 'zhaoming',
        // 电子停车牌
        'dianzitinchepai': 'dianzitinchepai',
        // 网关
        'wangguan': 'wangguan',
        // 道闸设备
        'daozhashebei': 'daozhashebei',
        // 道闸监控
        'daozhajiankong': 'daozhajiankong',
        //充电监控
        'changdijiankong': 'changdijiankong',
        // 充电桩
        'chongdianzhuang': "chongdianzhuang",
        // 环境质量
        'huanjingzhiliang': 'huanjingzhiliang'
      }


      mounted() {
        // 获取模型点位
        this.getPointModel();

        // 添加看板
        this.addSprite();

        // 车位看板
        // this.addChewei();

        // 设置dom的显示隐藏
        bus.$on('set-sprite-display', this.isShowSprite.bind(this))

        // 关闭所有的看板
        bus.$on('closeAllSprite', this.closeAllSprite.bind(this))

        console.log('cheweiMap:', this.pointMap);
      }

      // 获取模型点位
      getPointModel() {
        Object.keys(this.pointMap).map((floor) => {
          const { name } = this.pointMap[floor];
          getModel(name).children.map((chewei) => {
            const childName = chewei.name;
            const num = childName.indexOf("_");
            this.pointMap[floor].point[childName.substring(0, num)] = getModel(
              childName
            );
          });
        });
      }

      // 添加看板
      addSprite() {
        spriteDom.map((modelName) => {
          let dom = document.querySelector(`.${modelName}`);
          const sprite = Utils.domTo3DSprite(dom);
          sprite.scale.set(0.1, 0.1, 0.1);
          // dom.classList.add("showOpacity");
          const model = getModel(modelName)
          model.add(sprite);
          // sprite.scale.set(0, 0, 0)

          sprite.position.y += 10;

          sprite.visible = false

          // 添加对应的看板
          const spriteName = modelName.replace(/[0-9]+/g, '').replace('_', '')

          switch (spriteName) {

            case 'warning':
              this.spriteDomMap['warning'][warningArray.includes(modelName)
                ? 'warning'
                : standbyArray.includes(modelName)
                  ? 'standby'
                  : 'normal'][modelName] = {
                dom,
                model: sprite
              }
              break;

            case 'fengji':
              this.spriteDomMap['fengjiB2'][warningArray.includes(modelName)
                ? 'warning'
                : standbyArray.includes(modelName)
                  ? 'standby'
                  : 'normal'][modelName] = {
                dom,
                model: sprite
              }
              break;
            case 'zhaoming':
              this.spriteDomMap['zhaoming'][warningArray.includes(modelName)
                ? 'warning'
                : standbyArray.includes(modelName)
                  ? 'standby'
                  : 'normal'][modelName] = {
                dom,
                model: sprite
              }
              break;
            case 'wangguan':
              this.spriteDomMap['wangguan'][warningArray.includes(modelName)
                ? 'warning'
                : standbyArray.includes(modelName)
                  ? 'standby'
                  : 'normal'][modelName] = {
                dom,
                model: sprite
              }
              break;
            case 'chongdianzhuang':
              this.spriteDomMap['chongdianzhuang'][warningArray.includes(modelName)
                ? 'warning'
                : standbyArray.includes(modelName)
                  ? 'standby'
                  : 'normal'][modelName] = {
                dom,
                model: sprite
              }
              break;
            case 'changdijiankong':
              this.spriteDomMap['changdijiankong'][warningArray.includes(modelName)
                ? 'warning'
                : standbyArray.includes(modelName)
                  ? 'standby'
                  : 'normal'][modelName] = {
                dom,
                model: sprite
              }
              break;
            case 'daozhajiankong':
              this.spriteDomMap['daozhajiankong'][warningArray.includes(modelName)
                ? 'warning'
                : standbyArray.includes(modelName)
                  ? 'standby'
                  : 'normal'][modelName] = {
                dom,
                model: sprite
              }
              break;
            case 'daozhashebei':
              this.spriteDomMap['daozhashebei'][warningArray.includes(modelName)
                ? 'warning'
                : standbyArray.includes(modelName)
                  ? 'standby'
                  : 'normal'][modelName] = {
                dom,
                model: sprite
              }
              break;
            case 'dianzitinchepai':
              this.spriteDomMap['dianzitinchepai'][warningArray.includes(modelName)
                ? 'warning'
                : standbyArray.includes(modelName)
                  ? 'standby'
                  : 'normal'][modelName] = {
                dom,
                model: sprite
              }
              break;
            case 'huanjingzhiliang':
              this.spriteDomMap['huanjingzhiliang'][warningArray.includes(modelName)
                ? 'warning'
                : standbyArray.includes(modelName)
                  ? 'standby'
                  : 'normal'][modelName] = {
                dom,
                model: sprite
              }
              break;
            case 'bianpeidian':
              this.spriteDomMap['bianpeidian'][warningArray.includes(modelName)
                ? 'warning'
                : standbyArray.includes(modelName)
                  ? 'standby'
                  : 'normal'][modelName] = {
                dom,
                model: sprite
              }
              break;


            default:
          }
        });
      }

      // 车位看板
      addChewei() {
        pointB2.map((modelName) => {
          let dom = document.querySelector(`.${modelName}`);
          const sprite = Utils.domTo3Dui(dom);
          const model = getModel(modelName)
          model.add(sprite);
          // sprite.visible = false
          sprite.scale.set(0.0001, 0.0001, 0.0001);
          this.cheweiMap[modelName] = {
            dom,
            model
          }
        });
      }

      // 显示对应的dom
      isShowSprite(moduleName, state, isShow) {
        store.state.isInner = moduleName
        store.state.innerType = state
        // 先关闭所有的
        this.closeAllSprite();

        if (state === 'all') {
          this._isShowSprite(moduleName, 'warning', isShow)
          this._isShowSprite(moduleName, 'normal', isShow)
          this._isShowSprite(moduleName, 'standby', isShow)
        } else {
          this._isShowSprite(moduleName, state, isShow)
        }

      }

      _isShowSprite(moduleName, state, isShow) {
        const mapName = this.webNameMap[moduleName];
        const domMap = this.spriteDomMap[mapName]?.[state];
        if (!mapName || !domMap) return
        Object.keys(domMap).map((name) => {
          const { dom, model } = domMap[name];
          dom.classList[isShow ? 'add' : 'remove']('showOpacity');
          model.scale.set(isShow ? 0.1 : 0, isShow ? 0.1 : 0, isShow ? 0.1 : 0);
          model.visible = isShow;
        })
      }

      _addSprite() {
        pointB2.map((modelName) => {
          let dom = document.querySelector(`.${modelName}`);
          const sprite = Utils.domTo3Dui(dom);
          sprite.scale.set(0.001, 0.001, 0.001);
          dom.classList.add("showOpacity");
          getModel(modelName).add(sprite);
        });
      }

      // 关闭所有看板
      closeAllSprite() {
        bus.$emit("set-state-dialogThree", false)
        Object.keys(this.spriteDomMap).map((moduleName) => {
          Object.keys(this.spriteDomMap[moduleName]).map((state) => {
            this._isShowSprite(moduleName, state, false)
          })
        })

      }
    }

    // 快照
    class Snapshot {
      name = "snapshot";
      mounted() {
        // 快照
        bus.$on("snapShotFn", this.snapShotFn.bind(this));
      }
      // 快照
      snapShotFn(name) {
        runScene.snapshot.set(name);
      }
    }

    class EnergyManagement {
      name = 'energyManagement';
      energyModel = null;
      mounted() {
        this.getModel()

        bus.$on('isShow-energt-model', (name) => {
          this.energyModel.visible = name === 'energy'
        })

        bus.$emit('isShow-energt-model', false)
      }
      getModel() {
        this.energyModel = getModel('reliqu')
      }
    }

    // 选择楼层
    class Floor {

      name = "floor";

      // 选择映射表
      floorMap = {
        ground: "",
        b1: "2楼",
        b2: "",
        all: "初始",
      };

      // 0代表初始 1代表爆炸
      nowFloor = 0;

      mounted() {
        // 楼层选择
        bus.$on("floorSelection", this.floorSelection.bind(this));
      }

      // 楼层选择
      async floorSelection(name) {
        const sceneName = this.floorMap[name];

        if (!sceneName) return;

        // 快照 与相机点位
        await new Promise((res) => {
          // 选择初始且 之前不是初始即可执行
          if (sceneName === "初始") {
            if (this.nowFloor !== 0) {
              core.snapshot.snapShotFn("初始");
            }
          } else {
            // 选择炸开 之前不是炸开即可执行
            if (this.nowFloor === 0) {
              core.snapshot.snapShotFn("炸开");
            }
          }
          // 延迟执行
          Utils.getMacro(() => res(), 1000);
        })
          .then(() => {
            core.snapshot.snapShotFn(sceneName);
          })
          .finally(() => {
            runScene.cameraEx.setTemp(sceneName, { time: 2 });

            if (sceneName === "初始") {
              this.nowFloor = 0;
            } else {
              this.nowFloor = 1;
            }
            // bus.$emit('isShowCar', false);

            // bus.$emit('showCarState', '停车', true)

          });
      }
    }

    // 车位
    class Chewei {

      name = "chewei";

      carMap = {
        '停车': {},
        '无车': {},
      }
      carArray = []

      mounted() {
        // 获取模型
        this.getModel()

        this.getCarArray();

        // 是否显示 所有车
        bus.$on('isShowCar', this.isShowCar.bind(this))

        // 显示 有车 无车
        bus.$on('showCarState', this.showCarState.bind(this))

        this.isShowCar(false)

      }

      getCarArray() {
        Object.keys(this.carMap['停车']).map((name) => {
          this.carArray.push(name);
        })
      }

      // 获取模型
      getModel() {
        Object.keys(this.carMap).map((name) => {
          const tags = runScene.tags.get(name);
          tags.map((modelName) => {
            const model = runScene.modelEx.getById(modelName);
            this.carMap[name][model.name] = model;
          });
        })
      }

      // 是否显示车
      isShowCar(isShow) {
        Object.keys(this.carMap).map((state) => {
          Object.values(this.carMap[state]).map((m) => {
            m.visible = isShow
          })
        })
      }

      // 显示哪一个
      showCarState(what, isShow) {
        Object.values(this.carMap[what]).map((m) => {
          m.visible = isShow
        })
      }

      // 判断是否有车
      isHaveCar(name) {
        return this.carArray.includes(name)
      }
    }


    // 基本事件
    class Events {
      name = "events";

      clickModel = null;

      isRunning = true;

      constructor() {
        runScene.cb.model.setSelect.add(
          "trigger-click",
          this.triggerClick.bind(this)
        );

        this.refreshDom()

        bus.$on('sceneRunning2', (isRunnig) => {
          this.isRunning = isRunnig
        })
      }

      triggerClick = (model) => {

        if (!model) return;

        bus.$emit("logClickModel", model);

        // 点击车位看板
        if (model.name.includes('chewei')) {

          const ps = this.getXy(model);

          bus.$emit("set-position-dialogThree", '', '', ps);

          bus.$emit("set-state-dialogThree", true)
          if (router.currentRoute.name == 'parking') {
            store.state.innerType = core.chewei.isHaveCar(model.name) ? 'normal' : 'warning'

          }
          this.clickModel = model;

          console.log('点位----ps:', ps);

        } else {


          // 清空点击的模型
          this.clickModel = null;
        }

      };

      // 刷新dom
      refreshDom() {

        runScene.cb.controls.change.add("setDialogPosition", () => {

          if (!this.clickModel) return

          if (!this.isRunning) return

          const map = { outDom: document.querySelector('#app'), model: this.clickModel, camera }

          const { left: x, top: y } = this.get2DVec(map);

          const ps = { x, y };

          // console.log('x:', x, y);

          bus.$emit("set-position-dialogThree", '', '', ps);
        });
      }

      // 3d坐标转2位坐标
      get2DVec(map) {
        const { camera, model, outDom } = map;
        const { clientWidth, clientHeight } = outDom;
        const halfWidth = clientWidth / 2;
        const halfHeight = clientHeight / 2;
        const camPos = new Three.Vector3();
        const camDir = new Three.Vector3();
        camera.getWorldPosition(camPos);
        camera.getWorldDirection(camDir);
        const objPos = new Three.Vector3();
        model.updateMatrixWorld();
        objPos.setFromMatrixPosition(model.matrixWorld);
        const ndcPos = objPos.clone();
        ndcPos.project(camera);
        const objDir = new Three.Vector3();
        objDir.subVectors(objPos, camPos);
        objDir.normalize();
        const dotValue = camDir.dot(objDir);
        const sign = dotValue > 0 ? 1 : -1;
        const left = (1 + sign * ndcPos.x) * halfWidth;
        const top = (1 - sign * ndcPos.y) * halfHeight;
        return {
          left,
          top,
        };
      }

      getXy(model) {

        const map = { outDom: document.querySelector('#app'), model, camera }

        const { left: x, top: y } = this.get2DVec(map);

        const ps = { x, y };

        return ps
      }

      dispose() {
        controls.removeEventListener("start", this.controlStart);
      }
    }

    return [Events, InitScene, Point, Snapshot, Floor, Chewei, Camera, EnergyManagement];
  };

  const modules = fn({
    runScene,
    getModel: runScene.modelEx.getModel.bind(runScene.modelEx),
    core: runScene.custom,
    ...runScene.assetsEx.get(),
    ...inputData,
    constant,
    window: null,
  });

  if (!modules) return;

  modules
    .map((TheClass) => {
      const ins = new TheClass();
      if (!ins.name) throw TypeError("代码出错");
      runScene.custom[ins.name] = ins;
      return ins;
    })
    .map((ins) => ins?.mounted?.());
};
export { fn };
