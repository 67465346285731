<template>
  <div class="dialogThree" ref="dialogThree" v-show="isShow" @click="close">
    <div class="close" @click="isShow = false"></div>
    <!-- {{$store.state}} -->
    <img class="base-image" :src="getImage" alt="" />
    <!-- {{ $store.state.isInner }}-{{ $store.state.innerType }} -->
  </div>
</template>
<script>
import bus from "./../../lib/bus";
export default {
  name: "dialogThree",
  data() {
    return {
      isShow: false,
      img: null,
      item: null,
    };
  },
  computed: {
    getImage() {
      return (
          process.env.VUE_APP_BASE_ASSETS +
        "assets/images/" +
        this.$store.state.isInner +
        "-" +
        this.$store.state.innerType1 +
        ".png"
        // process.env.VUE_APP_BASE_ASSETS +
        // "assets/images/" +
        // this.$store.state.isInner +
        // "-" +
        // this.$store.state.innerType +
        // ".png"
      );
    },
  },
  mounted() {
    // 设置位置
    bus.$on("set-position-dialogThree", this.setPosition);

    // 是否显示
    bus.$on("set-state-dialogThree", (isShow) => {
      this.isShow = isShow;
      // this.$refs["dialogThree"].style.display = `${isShow ? "block" : "none"}`;
    });
  },
  watch: {
    isShow() {},
  },
  methods: {
    close() {
      bus.$emit("openVideo", this.item);
      this.isShow = false;
    },

    setPosition(_, el, ps) {
      this.img = _.img;

      this.item = _;
      let x = 0;
      let y = 0;
      let width = 0;
      let height = 0;

      if (ps) {
        x = ps.x;
        y = ps.y;
      } else {
        x = el.target.getBoundingClientRect().x;
        y = el.target.getBoundingClientRect().y;
        width = el.target.getBoundingClientRect().width;
        height = el.target.getBoundingClientRect().height;
      }

      let dom = document.querySelector(".dialogThree");

      dom.style.left = 2 + x + "px";

      dom.style.top = height + y + "px";

      // console.log("height:", height);

      // console.log("dom:", dom);
    },
  },
};
</script>

<style lang="scss" scoped>
.close {
  position: absolute;
  right: 6px;
  width: 16px;
  height: 16px;
  background-image: url("./close.png");
  background-size: 100% 100%;
  top: 6px;
  cursor: pointer;
}
.dialogThree {
  position: absolute;
  z-index: 50;
  // background-color: pink;
}

.base-image {
  width: 280px;
}
</style>
