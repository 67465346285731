/**
 * @description 路由信息
 */

export default [
    {
        name: "enviroment",
        path: "/",
        component: () => import("@/views/environment"),
        meta: {
            name: "首页",
        },
    },
    {
        name: "energy",
        path: "/energy",
        component: () => import("@/views/energy"),
        meta: {
            name: "首页",
        },
    },
    {
        name: "device",
        path: "/device",
        component: () => import("@/views/device"),
        meta: {
            name: "首页",
        },
    },
    {
        name: "parking",
        path: "/parking",
        component: () => import("@/views/parking"),
        meta: {
            name: "首页",
        },
    },
    {
        name: "monitor",
        path: "/monitor",
        component: () => import("@/views/monitor"),
        meta: {
            name: "首页",
        },
    },
    {
        name: "error",
        path: "/error",
        component: () => import("@/views/error"),
        meta: {
            name: "首页",
        },
    },

    {
        name: "login",
        path: "/login",
        component: () => import("@/views/login"),
        meta: {
            name: "登录",
        },
    },
]
