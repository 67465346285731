<style lang="scss" scoped>
.haikang-monitor {
  width: 100%;
  height: 100%;
}
</style>

<template>
  <video class="haikang-monitor" controls ref="haikang-monitor">
    <!-- {{ url }} -->
  </video>
</template>

<script>
import axios from "axios";
export default {
  name: "haikang-monitor",
  props: {
    url: String,
  },
  mounted() {},
  methods: {
    async initFlv() {
      let { data } = await axios.request({
        method: "post",
        url: `${process.env.VUE_APP_BASE_HAIKANG_URL}/putVideo`,
        params: {
          path: this.url,
        },
      });

      let url = `${process.env.VUE_APP_BASE_HAIKANG_URL}/getVideo?id=` + data;
      let flvPlayer = flvjs.createPlayer({
        type: "flv",
        isLive: true, //<====加个这个
        url,
      });
      flvPlayer.attachMediaElement(this.$refs["haikang-monitor"]);
      flvPlayer.load(); //加载
      flvPlayer.play();
      this.flvPlayer = flvPlayer;
      this.$refs["haikang-monitor"].play();
      // flv_start();
    },
    destroyFlv() {
      this.flvPlayer.pause(); //停止播放
      this.flvPlayer.unload(); //停止加载
      this.flvPlayer.detachMediaElement(); //销毁实例
      this.flvPlayer.destroy();
      this.flvPlayer = null;
    },
  },
  mounted() {
    // window.onclick=()=>{
    this.initFlv();
    // window.onclick=null
    // }
  },
  destroyed() {
    this.destroyFlv();
  },
};
</script>
