<style lang="scss" scoped>
.normal_sprite {
  // width: 55px;
  // height: 55px;
  // background: red;
}
.huanjingzhiliang {
  .normal_sprite {
    width: 66px;
    height: 154px;
    background-image: url("../../assets/point/huanjingzhiliang.png");
    cursor: pointer;
    background-size: 100% 100%;
  }
  .warning_sprite {
    width: 66px;
    height: 154px;
    background-image: url("../../assets/point/huanjingzhiliang-warning.png");
    cursor: pointer;
    background-size: 100% 100%;
  }
}
.daozhajiankong {
  .normal_sprite {
    width: 66px;
    height: 154px;
    background-image: url("../../assets/point/monitor.png");
    cursor: pointer;
    background-size: 100% 100%;
  }
  .warning_sprite {
    width: 66px;
    height: 154px;
    background-image: url("../../assets/point/monitor-warning.png");
    cursor: pointer;
    background-size: 100% 100%;
  }

  .standby_sprite {
    width: 66px;
    height: 154px;
    background-image: url("../../assets/point/monitor-standby.png");
    cursor: pointer;
    background-size: 100% 100%;
  }
}

.dianzitinchepai {
  .normal_sprite {
    width: 66px;
    height: 154px;
    background-image: url("../../assets/point/dianzitinchepai-normal.png");
    cursor: pointer;
    background-size: 100% 100%;
  }
}

.wangguan {
  .normal_sprite {
    width: 66px;
    height: 154px;
    background-image: url("../../assets/point/wangguan-normal.png");
    cursor: pointer;
    background-size: 100% 100%;
  }
}

.daozhashebei {
  .normal_sprite {
    width: 66px;
    height: 154px;
    background-image: url("../../assets/point/daozhashebei-normal.png");
    cursor: pointer;
    background-size: 100% 100%;
  }
}
.gaojing {
  .normal_sprite {
    width: 66px;
    height: 154px;
    background-image: url("../../assets/point/gaojing-normal.png");
    cursor: pointer;
    background-size: 100% 100%;
  }
  .warning_sprite {
    width: 66px;
    height: 154px;
    background-image: url("../../assets/point/gaojing-warning.png");
    cursor: pointer;
    background-size: 100% 100%;
  }

  .standby_sprite {
    width: 66px;
    height: 154px;
    background-image: url("../../assets/point/gaojing-normal.png");
    cursor: pointer;
    background-size: 100% 100%;
  }
}
.bianpeidian {
  .normal_sprite {
    width: 66px;
    height: 154px;
    background-image: url("../../assets/point/bianpeidian-normal.png");
    cursor: pointer;
    background-size: 100% 100%;
  }
  .warning_sprite {
    width: 66px;
    height: 154px;
    background-image: url("../../assets/point/bianpeidian-warning.png");
    cursor: pointer;
    background-size: 100% 100%;
  }

  .standby_sprite {
    width: 66px;
    height: 154px;
    background-image: url("../../assets/point/bianpeidian-normal.png");
    cursor: pointer;
    background-size: 100% 100%;
  }
}
.zhaoming {
  .normal_sprite {
    width: 66px;
    height: 154px;
    background-image: url("../../assets/point/zhaoming-normal.png");
    cursor: pointer;
    background-size: 100% 100%;
  }
  .warning_sprite {
    width: 66px;
    height: 154px;
    background-image: url("../../assets/point/zhaoming-normal.png");
    cursor: pointer;
    background-size: 100% 100%;
  }

  .standby_sprite {
    width: 66px;
    height: 154px;
    background-image: url("../../assets/point/zhaoming-normal.png");
    cursor: pointer;
    background-size: 100% 100%;
  }
}
.warning {
  .normal_sprite {
    width: 66px;
    height: 154px;
    background-image: url("../../assets/point/gaojing-normal.png");
    cursor: pointer;
    background-size: 100% 100%;
  }

  .warning_sprite {
    width: 66px;
    height: 154px;
    background-image: url("../../assets/point/gaojing-warning.png");
    cursor: pointer;
    background-size: 100% 100%;
  }
}
</style>

<template>
  <div class="three-scene" ref="three-scene" onselectstart="return false;">
    <div @pointerdown="(e) => e.stopPropagation()" class="btn"></div>

    <div
      :class="'sprites ' + i + ' ' + i.replace(/[0-9]+/g, '').replace('_', '')"
      v-for="(i, index) in spriteDom"
    >
      <div
        :class="
          warningArray.includes(i)
            ? 'warning_sprite'
            : standbyArray.includes(i)
            ? 'standby_sprite'
            : 'normal_sprite'
        "
        @pointerdown="
          openDialog(
            i,
            $event,
            warningArray.includes(i)
              ? 'warning_sprite'
              : standbyArray.includes(i)
              ? 'standby_sprite'
              : 'normal_sprite'
          )
        "
      >
        <!-- {{ i }} -->
      </div>
      <div class="sprites-bottom"></div>
    </div>

    <!-- <div
      :class="'sprites ' + i + ' ' + i.replace(/[0-9]+/g, '').replace('_', '')"
      v-for="(i, index) in pointB2"
    ></div> -->
  </div>
</template>

<script>
// chang 事件 实例
let sceneChange = null;
// 场景
let scene = null;
import Engine from "run-scene-v2";
import store from "../../store";
import * as THREE from "three";
import { fn } from "./Change";
import bus from "./../../lib/bus";
import router from "../../router";
import {
  pointB2,
  fengjiB2,
  zhaoming,
  wangguan,
  chongdianzhuang,
  changdijiankong,
  daozhajiankong,
  daozhashebei,
  dianzitinchepai,
  huanjingzhiliang,
  bianpeidian,
  warningArray,
  standbyArray,
  gaojing,
} from "./const";
const { RunScene, Utils } = Engine;
export default {
  name: "Threescene",
  data() {
    return {
      path:
        // "http://192.168.3.8:8080/file?path=project/linkpoint/&key=202306151643510187701001202324",
        "https://3d-editor-2-1303915342.cos.ap-shanghai.myqcloud.com/202306151643510187701001202324.glb",
      warningArray: warningArray,
      standbyArray: standbyArray,
      pointB2: pointB2,
      spriteDom: [
        ...fengjiB2,
        ...zhaoming,
        ...wangguan,
        ...chongdianzhuang,
        ...changdijiankong,
        ...daozhajiankong,
        ...daozhashebei,
        ...dianzitinchepai,
        ...huanjingzhiliang,
        ...bianpeidian,
        ...gaojing,
      ],
      isRuning: true,
    };
  },
  async mounted() {
    const runScene = await this.loadScene(this.path);
    // 加载场景

    // 打印点击的模型接口
    bus.$on("logClickModel", this.logClickModel);

    // 场景加载完回调
    runScene.on("loaded", () => {
      this.resize(store.state.fitLayoutRatio, scene);

      this.$refs["three-scene"].classList.add("show");
      this.$emit("load");
      fn(
        scene,
        {
          Utils,
          bus,
          Three: THREE,
          store,
        },
        {
          pointB2,
          fengjiB2,
          zhaoming,
          wangguan,
          chongdianzhuang,
          changdijiankong,
          daozhajiankong,
          daozhashebei,
          dianzitinchepai,
          huanjingzhiliang,
          bianpeidian,
          warningArray,
          standbyArray,
          gaojing,
        }
      );

      this.onDone();
    });

    // 场景运行状态
    bus.$on("sceneRunning1", (isRuning) => {
      this.isRuning = isRuning;
    });
  },
  methods: {
    // 加载场景
    loadScene(path) {
      scene = new RunScene({
        render2: true,
        render3: true,
        renderConfig: {
          // 是否允许设置模型位置后自动渲染最新效果
          matrixAutoUpdate: true,
          scriptFrame: 60,
          event: {
            ignores: ["resize"],
          },
        },
      }).load({
        path: path,
        dom: this.$refs["three-scene"],
      });

      scene.setSize(3040, 1040);

      return scene;
    },

    openDialog(item, event, type) {
      this.$store.state.innerType1 = type.split("_")[0];
      scene?.cb.controls.change.add("setDialogPosition", () => {
        if (!this.isRuning) return;
        bus.$emit("set-position-dialogThree", item, event);
      });

      // 设置 唯一dom位置
      bus.$emit("set-position-dialogThree", item, event);

      bus.$emit("set-state-dialogThree", true);
    },

    // 自适应
    resize: (x, runScene) => {
      let map = runScene.assetsEx.engineDom.getBoundingClientRect();
      runScene.setSize(map.width / x, map.height / x);
    },
    // 加载完回调
    onDone() {
      // console.log("场景加载完毕~");
    },
    // 快照
    snapshot(name) {
      bus.$emit("snapShotFn", name);
    },
    // 打印点击到的模型
    logClickModel(model) {
      console.log("点击的模型为:", model.name);
    },
  },
  // 场景自带销毁
  destroyed() {
    sceneChange && sceneChange.dispose();
  },
};
</script>

<style lang="scss" scoped>
// 场景
.three-scene {
  width: 100%;
  height: 100%;
  pointer-events: all;
  background-color: black;
}

.three-scene .btn {
  position: absolute;
  z-index: 20;
}

.three-scene .show {
  opacity: 1 !important;
}

.three-scene .none {
  opacity: 0 !important;
}

.three-scene .block {
  display: block !important;
}

.sprites {
  position: absolute;
  width: 35px;
  height: 80px;
  // opacity: 0;
  // background-color: pink;
  > div {
    width: 100%;
    height: 100%;
    color: white;
    position: absolute;

    top: 50%;
    transform: translateY(-50%);
  }

  // .sprites-bottom {
  //   width: 66px;
  //   height: 154px;
  // }

  .warning_sprite {
    // background-color: red;
  }

  .normal_sprite {
    // background-color: rgb(46, 85, 132);
  }

  .standby_sprite {
    // background-color: rgb(112, 119, 138);
  }
}

.showOpacity {
  opacity: 1 !important;
}
</style>
