var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"three-scene",staticClass:"three-scene",attrs:{"onselectstart":"return false;"}},[_c('div',{staticClass:"btn",on:{"pointerdown":(e) => e.stopPropagation()}}),_vm._l((_vm.spriteDom),function(i,index){return _c('div',{class:'sprites ' + i + ' ' + i.replace(/[0-9]+/g, '').replace('_', '')},[_c('div',{class:_vm.warningArray.includes(i)
          ? 'warning_sprite'
          : _vm.standbyArray.includes(i)
          ? 'standby_sprite'
          : 'normal_sprite',on:{"pointerdown":function($event){_vm.openDialog(
          i,
          $event,
          _vm.warningArray.includes(i)
            ? 'warning_sprite'
            : _vm.standbyArray.includes(i)
            ? 'standby_sprite'
            : 'normal_sprite'
        )}}}),_c('div',{staticClass:"sprites-bottom"})])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }