// 车位
const pointB2 = [
    "chewei315_1",
    "chewei197_1",
    "chewei263_1",
    "chewei125_1",
    "chewei487_1",
    "chewei760_1",
    "chewei629_1",
    "chewei808_1",
    "chewei496_1",
    "chewei830_1",
    "chewei776_1",
    "chewei869_1",
    "chewei545_1",
    "chewei390_1",
    "chewei705_1",
    "chewei85_1",
    "chewei276_1",
    "chewei260_1",
    "chewei594_1",
    "chewei111_1",
    "chewei913_1",
    "chewei306_1",
    "chewei402_1",
    "chewei76_1",
    "chewei614_1",
    "chewei113_1",
    "chewei194_1",
    "chewei564_1",
    "chewei60_1",
    "chewei351_1",
    "chewei700_1",
    "chewei542_1",
    "chewei783_1",
    "chewei443_1",
    "chewei473_1",
    "chewei297_1",
    "chewei126_1",
    "chewei634_1",
    "chewei763_1",
    "chewei933_1",
    "chewei409_1",
    "chewei532_1",
    "chewei859_1",
    "chewei137_1",
    "chewei461_1",
    "chewei702_1",
    "chewei829_1",
    "chewei344_1",
    "chewei230_1",
    "chewei62_1",
    "chewei631_1",
    "chewei182_1",
    "chewei331_1",
    "chewei211_1",
    "chewei63_1",
    "chewei854_1",
    "chewei888_1",
    "chewei823_1",
    "chewei129_1",
    "chewei391_1",
    "chewei778_1",
    "chewei718_1",
    "chewei506_1",
    "chewei39_1",
    "chewei24_1",
    "chewei652_1",
    "chewei248_1",
    "chewei525_1",
    "chewei478_1",
    "chewei348_1",
    "chewei568_1",
    "chewei453_1",
    "chewei257_1",
    "chewei770_1",
    "chewei560_1",
    "chewei447_1",
    "chewei901_1",
    "chewei621_1",
    "chewei690_1",
    "chewei87_1",
    "chewei462_1",
    "chewei142_1",
    "chewei93_1",
    "chewei242_1",
    "chewei921_1",
    "chewei210_1",
    "chewei731_1",
    "chewei540_1",
    "chewei643_1",
    "chewei185_1",
    "chewei21_1",
    "chewei795_1",
    "chewei698_1",
    "chewei112_1",
    "chewei751_1",
    "chewei469_1",
    "chewei47_1",
    "chewei300_1",
    "chewei582_1",
    "chewei381_1",
    "chewei687_1",
    "chewei361_1",
    "chewei865_1",
    "chewei456_1",
    "chewei295_1",
    "chewei780_1",
    "chewei655_1",
    "chewei150_1",
    "chewei892_1",
    "chewei593_1",
    "chewei917_1",
    "chewei27_1",
    "chewei598_1",
    "chewei224_1",
    "chewei651_1",
    "chewei316_1",
    "chewei147_1",
    "chewei504_1",
    "chewei675_1",
    "chewei437_1",
    "chewei98_1",
    "chewei383_1",
    "chewei886_1",
    "chewei189_1",
    "chewei802_1",
    "chewei356_1",
    "chewei303_1",
    "chewei53_1",
    "chewei17_1",
    "chewei565_1",
    "chewei743_1",
    "chewei193_1",
    "chewei501_1",
    "chewei877_1",
    "chewei673_1",
    "chewei745_1",
    "chewei250_1",
    "chewei61_1",
    "chewei849_1",
    "chewei782_1",
    "chewei251_1",
    "chewei328_1",
    "chewei221_1",
    "chewei524_1",
    "chewei387_1",
    "chewei16_1",
    "chewei555_1",
    "chewei426_1",
    "chewei149_1",
    "chewei342_1",
    "chewei665_1",
    "chewei158_1",
    "chewei225_1",
    "chewei606_1",
    "chewei416_1",
    "chewei71_1",
    "chewei169_1",
    "chewei790_1",
    "chewei758_1",
    "chewei906_1",
    "chewei237_1",
    "chewei312_1",
    "chewei841_1",
    "chewei382_1",
    "chewei583_1",
    "chewei401_1",
    "chewei455_1",
    "chewei670_1",
    "chewei656_1",
    "chewei870_1",
    "chewei18_1",
    "chewei101_1",
    "chewei213_1",
    "chewei571_1",
    "chewei277_1",
    "chewei861_1",
    "chewei659_1",
    "chewei470_1",
    "chewei788_1",
    "chewei31_1",
    "chewei156_1",
    "chewei719_1",
    "chewei346_1",
    "chewei75_1",
    "chewei517_1",
    "chewei151_1",
    "chewei423_1",
    "chewei625_1",
    "chewei183_1",
    "chewei637_1",
    "chewei370_1",
    "chewei646_1",
    "chewei759_1",
    "chewei56_1",
    "chewei832_1",
    "chewei479_1",
    "chewei710_1",
    "chewei689_1",
    "chewei873_1",
    "chewei83_1",
    "chewei49_1",
    "chewei472_1",
    "chewei448_1",
    "chewei207_1",
    "chewei792_1",
    "chewei350_1",
    "chewei246_1",
    "chewei768_1",
    "chewei281_1",
    "chewei853_1",
    "chewei585_1",
    "chewei240_1",
    "chewei553_1",
    "chewei304_1",
    "chewei395_1",
    "chewei35_1",
    "chewei664_1",
    "chewei640_1",
    "chewei576_1",
    "chewei33_1",
    "chewei337_1",
    "chewei220_1",
    "chewei744_1",
    "chewei724_1",
    "chewei136_1",
    "chewei408_1",
    "chewei86_1",
    "chewei884_1",
    "chewei420_1",
    "chewei145_1",
    "chewei173_1",
    "chewei264_1",
    "chewei814_1",
    "chewei163_1",
    "chewei418_1",
    "chewei305_1",
    "chewei396_1",
    "chewei900_1",
    "chewei498_1",
    "chewei352_1",
    "chewei229_1",
    "chewei557_1",
    "chewei208_1",
    "chewei50_1",
    "chewei431_1",
    "chewei329_1",
    "chewei198_1",
    "chewei43_1",
    "chewei247_1",
    "chewei713_1",
    "chewei580_1",
    "chewei627_1",
    "chewei874_1",
    "chewei898_1",
    "chewei91_1",
    "chewei693_1",
    "chewei28_1",
    "chewei58_1",
    "chewei777_1",
    "chewei793_1",
    "chewei128_1",
    "chewei518_1",
    "chewei389_1",
    "chewei851_1",
    "chewei750_1",
    "chewei105_1",
    "chewei178_1",
    "chewei488_1",
    "chewei885_1",
    "chewei703_1",
    "chewei927_1",
    "chewei815_1",
    "chewei613_1",
    "chewei804_1",
    "chewei463_1",
    "chewei102_1",
    "chewei605_1",
    "chewei452_1",
    "chewei92_1",
    "chewei90_1",
    "chewei715_1",
    "chewei530_1",
    "chewei271_1",
    "chewei286_1",
    "chewei726_1",
    "chewei569_1",
    "chewei881_1",
    "chewei688_1",
    "chewei313_1",
    "chewei359_1",
    "chewei608_1",
    "chewei809_1",
    "chewei412_1",
    "chewei205_1",
    "chewei550_1",
    "chewei876_1",
    "chewei26_1",
    "chewei460_1",
    "chewei653_1",
    "chewei819_1",
    "chewei697_1",
    "chewei475_1",
    "chewei537_1",
    "chewei285_1",
    "chewei369_1",
    "chewei343_1",
    "chewei784_1",
    "chewei177_1",
    "chewei165_1",
    "chewei932_1",
    "chewei616_1",
    "chewei365_1",
    "chewei84_1",
    "chewei19_1",
    "chewei294_1",
    "chewei548_1",
    "chewei138_1",
    "chewei754_1",
    "chewei97_1",
    "chewei397_1",
    "chewei543_1",
    "chewei486_1",
    "chewei779_1",
    "chewei292_1",
    "chewei141_1",
    "chewei424_1",
    "chewei756_1",
    "chewei14_1",
    "chewei513_1",
    "chewei566_1",
    "chewei648_1",
    "chewei270_1",
    "chewei393_1",
    "chewei99_1",
    "chewei787_1",
    "chewei339_1",
    "chewei617_1",
    "chewei895_1",
    "chewei678_1",
    "chewei433_1",
    "chewei699_1",
    "chewei430_1",
    "chewei155_1",
    "chewei816_1",
    "chewei611_1",
    "chewei204_1",
    "chewei765_1",
    "chewei672_1",
    "chewei562_1",
    "chewei195_1",
    "chewei843_1",
    "chewei74_1",
    "chewei918_1",
    "chewei310_1",
    "chewei259_1",
    "chewei552_1",
    "chewei355_1",
    "chewei612_1",
    "chewei244_1",
    "chewei534_1",
    "chewei554_1",
    "chewei523_1",
    "chewei539_1",
    "chewei497_1",
    "chewei864_1",
    "chewei449_1",
    "chewei494_1",
    "chewei64_1",
    "chewei139_1",
    "chewei712_1",
    "chewei354_1",
    "chewei725_1",
    "chewei298_1",
    "chewei78_1",
    "chewei825_1",
    "chewei180_1",
    "chewei192_1",
    "chewei686_1",
    "chewei82_1",
    "chewei771_1",
    "chewei41_1",
    "chewei805_1",
    "chewei434_1",
    "chewei890_1",
    "chewei106_1",
    "chewei833_1",
    "chewei848_1",
    "chewei866_1",
    "chewei266_1",
    "chewei140_1",
    "chewei903_1",
    "chewei626_1",
    "chewei172_1",
    "chewei179_1",
    "chewei372_1",
    "chewei171_1",
    "chewei400_1",
    "chewei103_1",
    "chewei742_1",
    "chewei290_1",
    "chewei80_1",
    "chewei746_1",
    "chewei474_1",
    "chewei549_1",
    "chewei684_1",
    "chewei333_1",
    "chewei644_1",
    "chewei883_1",
    "chewei602_1",
    "chewei677_1",
    "chewei364_1",
    "chewei154_1",
    "chewei727_1",
    "chewei386_1",
    "chewei837_1",
    "chewei235_1",
    "chewei444_1",
    "chewei912_1",
    "chewei367_1",
    "chewei840_1",
    "chewei289_1",
    "chewei521_1",
    "chewei785_1",
    "chewei318_1",
    "chewei110_1",
    "chewei465_1",
    "chewei822_1",
    "chewei215_1",
    "chewei454_1",
    "chewei667_1",
    "chewei512_1",
    "chewei733_1",
    "chewei856_1",
    "chewei72_1",
    "chewei118_1",
    "chewei378_1",
    "chewei445_1",
    "chewei796_1",
    "chewei57_1",
    "chewei676_1",
    "chewei645_1",
    "chewei241_1",
    "chewei432_1",
    "chewei741_1",
    "chewei574_1",
    "chewei704_1",
    "chewei249_1",
    "chewei916_1",
    "chewei615_1",
    "chewei222_1",
    "chewei45_1",
    "chewei929_1",
    "chewei186_1",
    "chewei683_1",
    "chewei800_1",
    "chewei299_1",
    "chewei323_1",
    "chewei769_1",
    "chewei879_1",
    "chewei100_1",
    "chewei579_1",
    "chewei411_1",
    "chewei468_1",
    "chewei376_1",
    "chewei813_1",
    "chewei766_1",
    "chewei599_1",
    "chewei842_1",
    "chewei834_1",
    "chewei59_1",
    "chewei674_1",
    "chewei209_1",
    "chewei321_1",
    "chewei544_1",
    "chewei347_1",
    "chewei893_1",
    "chewei695_1",
    "chewei406_1",
    "chewei581_1",
    "chewei353_1",
    "chewei385_1",
    "chewei546_1",
    "chewei880_1",
    "chewei217_1",
    "chewei573_1",
    "chewei458_1",
    "chewei508_1",
    "chewei42_1",
    "chewei218_1",
    "chewei280_1",
    "chewei96_1",
    "chewei20_1",
    "chewei635_1",
    "chewei561_1",
    "chewei696_1",
    "chewei466_1",
    "chewei234_1",
    "chewei196_1",
    "chewei471_1",
    "chewei338_1",
    "chewei73_1",
    "chewei144_1",
    "chewei749_1",
    "chewei915_1",
    "chewei818_1",
    "chewei438_1",
    "chewei584_1",
    "chewei451_1",
    "chewei850_1",
    "chewei38_1",
    "chewei176_1",
    "chewei642_1",
    "chewei791_1",
    "chewei341_1",
    "chewei914_1",
    "chewei267_1",
    "chewei669_1",
    "chewei417_1",
    "chewei516_1",
    "chewei288_1",
    "chewei108_1",
    "chewei600_1",
    "chewei735_1",
    "chewei373_1",
    "chewei860_1",
    "chewei104_1",
    "chewei515_1",
    "chewei256_1",
    "chewei407_1",
    "chewei738_1",
    "chewei500_1",
    "chewei330_1",
    "chewei421_1",
    "chewei908_1",
    "chewei268_1",
    "chewei891_1",
    "chewei609_1",
    "chewei226_1",
    "chewei919_1",
    "chewei636_1",
    "chewei596_1",
    "chewei199_1",
    "chewei166_1",
    "chewei311_1",
    "chewei9_1",
    "chewei575_1",
    "chewei214_1",
    "chewei511_1",
    "chewei115_1",
    "chewei671_1",
    "chewei25_1",
    "chewei722_1",
    "chewei324_1",
    "chewei661_1",
    "chewei855_1",
    "chewei589_1",
    "chewei773_1",
    "chewei319_1",
    "chewei481_1",
    "chewei109_1",
    "chewei161_1",
    "chewei291_1",
    "chewei130_1",
    "chewei302_1",
    "chewei68_1",
    "chewei922_1",
    "chewei660_1",
    "chewei846_1",
    "chewei23_1",
    "chewei619_1",
    "chewei167_1",
    "chewei514_1",
    "chewei905_1",
    "chewei36_1",
    "chewei384_1",
    "chewei228_1",
    "chewei709_1",
    "chewei415_1",
    "chewei863_1",
    "chewei538_1",
    "chewei820_1",
    "chewei37_1",
    "chewei590_1",
    "chewei510_1",
    "chewei740_1",
    "chewei811_1",
    "chewei317_1",
    "chewei404_1",
    "chewei527_1",
    "chewei867_1",
    "chewei135_1",
    "chewei595_1",
    "chewei429_1",
    "chewei587_1",
    "chewei707_1",
    "chewei89_1",
    "chewei812_1",
    "chewei638_1",
    "chewei223_1",
    "chewei216_1",
    "chewei682_1",
    "chewei278_1",
    "chewei499_1",
    "chewei772_1",
    "chewei77_1",
    "chewei160_1",
    "chewei405_1",
    "chewei734_1",
    "chewei308_1",
    "chewei375_1",
    "chewei131_1",
    "chewei15_1",
    "chewei902_1",
    "chewei327_1",
    "chewei798_1",
    "chewei872_1",
    "chewei272_1",
    "chewei623_1",
    "chewei345_1",
    "chewei489_1",
    "chewei716_1",
    "chewei495_1",
    "chewei65_1",
    "chewei283_1",
    "chewei835_1",
    "chewei107_1",
    "chewei737_1",
    "chewei314_1",
    "chewei654_1",
    "chewei34_1",
    "chewei284_1",
    "chewei828_1",
    "chewei666_1",
    "chewei570_1",
    "chewei721_1",
    "chewei29_1",
    "chewei493_1",
    "chewei630_1",
    "chewei839_1",
    "chewei572_1",
    "chewei187_1",
    "chewei425_1",
    "chewei121_1",
    "chewei714_1",
    "chewei446_1",
    "chewei911_1",
    "chewei203_1",
    "chewei274_1",
    "chewei781_1",
    "chewei51_1",
    "chewei175_1",
    "chewei440_1",
    "chewei114_1",
    "chewei287_1",
    "chewei505_1",
    "chewei439_1",
    "chewei335_1",
    "chewei817_1",
    "chewei774_1",
    "chewei357_1",
    "chewei577_1",
    "chewei882_1",
    "chewei30_1",
    "chewei909_1",
    "chewei200_1",
    "chewei647_1",
    "chewei907_1",
    "chewei79_1",
    "chewei170_1",
    "chewei601_1",
    "chewei836_1",
    "chewei309_1",
    "chewei767_1",
    "chewei720_1",
    "chewei127_1",
    "chewei490_1",
    "chewei398_1",
    "chewei559_1",
    "chewei236_1",
    "chewei371_1",
    "chewei794_1",
    "chewei148_1",
    "chewei124_1",
    "chewei930_1",
    "chewei255_1",
    "chewei483_1",
    "chewei301_1",
    "chewei852_1",
    "chewei748_1",
    "chewei519_1",
    "chewei706_1",
    "chewei427_1",
    "chewei8_1",
    "chewei4_1",
    "chewei5_1",
    "chewei1_1",
    "chewei3_1",
    "chewei2_1",
    "chewei6_1",
    "chewei597_1",
    "chewei685_1",
    "chewei578_1",
    "chewei910_1",
    "chewei904_1",
    "chewei377_1",
    "chewei132_1",
    "chewei95_1",
    "chewei219_1",
    "chewei618_1",
    "chewei484_1",
    "chewei894_1",
    "chewei492_1",
    "chewei422_1",
    "chewei275_1",
    "chewei732_1",
    "chewei831_1",
    "chewei762_1",
    "chewei464_1",
    "chewei325_1",
    "chewei657_1",
    "chewei279_1",
    "chewei547_1",
    "chewei535_1",
    "chewei624_1",
    "chewei22_1",
    "chewei168_1",
    "chewei232_1",
    "chewei10_1",
    "chewei326_1",
    "chewei729_1",
    "chewei436_1",
    "chewei556_1",
    "chewei67_1",
    "chewei641_1",
    "chewei122_1",
    "chewei875_1",
    "chewei801_1",
    "chewei897_1",
    "chewei403_1",
    "chewei394_1",
    "chewei528_1",
    "chewei541_1",
    "chewei253_1",
    "chewei146_1",
    "chewei806_1",
    "chewei459_1",
    "chewei753_1",
    "chewei153_1",
    "chewei775_1",
    "chewei509_1",
    "chewei262_1",
    "chewei238_1",
    "chewei201_1",
    "chewei658_1",
    "chewei273_1",
    "chewei803_1",
    "chewei633_1",
    "chewei184_1",
    "chewei567_1",
    "chewei340_1",
    "chewei858_1",
    "chewei362_1",
    "chewei44_1",
    "chewei650_1",
    "chewei691_1",
    "chewei188_1",
    "chewei360_1",
    "chewei799_1",
    "chewei457_1",
    "chewei13_1",
    "chewei133_1",
    "chewei928_1",
    "chewei239_1",
    "chewei868_1",
    "chewei607_1",
    "chewei826_1",
    "chewei526_1",
    "chewei728_1",
    "chewei191_1",
    "chewei88_1",
    "chewei261_1",
    "chewei507_1",
    "chewei442_1",
    "chewei388_1",
    "chewei157_1",
    "chewei476_1",
    "chewei931_1",
    "chewei692_1",
    "chewei54_1",
    "chewei366_1",
    "chewei558_1",
    "chewei258_1",
    "chewei752_1",
    "chewei701_1",
    "chewei11_1",
    "chewei282_1",
    "chewei252_1",
    "chewei143_1",
    "chewei889_1",
    "chewei12_1",
    "chewei708_1",
    "chewei399_1",
    "chewei862_1",
    "chewei392_1",
    "chewei164_1",
    "chewei923_1",
    "chewei380_1",
    "chewei757_1",
    "chewei717_1",
    "chewei233_1",
    "chewei363_1",
    "chewei810_1",
    "chewei663_1",
    "chewei485_1",
    "chewei293_1",
    "chewei730_1",
    "chewei152_1",
    "chewei503_1",
    "chewei604_1",
    "chewei414_1",
    "chewei94_1",
    "chewei649_1",
    "chewei531_1",
    "chewei610_1",
    "chewei807_1",
    "chewei480_1",
    "chewei52_1",
    "chewei551_1",
    "chewei821_1",
    "chewei307_1",
    "chewei536_1",
    "chewei844_1",
    "chewei174_1",
    "chewei243_1",
    "chewei845_1",
    "chewei592_1",
    "chewei334_1",
    "chewei48_1",
    "chewei896_1",
    "chewei736_1",
    "chewei410_1",
    "chewei502_1",
    "chewei926_1",
    "chewei212_1",
    "chewei202_1",
    "chewei603_1",
    "chewei529_1",
    "chewei761_1",
    "chewei563_1",
    "chewei119_1",
    "chewei428_1",
    "chewei639_1",
    "chewei69_1",
    "chewei694_1",
    "chewei336_1",
    "chewei668_1",
    "chewei824_1",
    "chewei797_1",
    "chewei755_1",
    "chewei419_1",
    "chewei632_1",
    "chewei920_1",
    "chewei332_1",
    "chewei764_1",
    "chewei265_1",
    "chewei857_1",
    "chewei134_1",
    "chewei680_1",
    "chewei827_1",
    "chewei887_1",
    "chewei522_1",
    "chewei231_1",
    "chewei81_1",
    "chewei477_1",
    "chewei747_1",
    "chewei117_1",
    "chewei628_1",
    "chewei374_1",
    "chewei254_1",
    "chewei245_1",
    "chewei159_1",
    "chewei924_1",
    "chewei491_1",
    "chewei123_1",
    "chewei662_1",
    "chewei32_1",
    "chewei620_1",
    "chewei899_1",
    "chewei871_1",
    "chewei786_1",
    "chewei723_1",
    "chewei40_1",
    "chewei679_1",
    "chewei441_1",
    "chewei379_1",
    "chewei450_1",
    "chewei66_1",
    "chewei46_1",
    "chewei533_1",
    "chewei55_1",
    "chewei349_1",
    "chewei847_1",
    "chewei320_1",
    "chewei622_1",
    "chewei190_1",
    "chewei586_1",
    "chewei467_1",
    "chewei181_1",
    "chewei520_1",
    "chewei681_1",
    "chewei296_1",
    "chewei588_1",
    "chewei269_1",
    "chewei120_1",
    "chewei789_1",
    "chewei116_1",
    "chewei413_1",
    "chewei925_1",
    "chewei878_1",
    "chewei591_1",
    "chewei435_1",
    "chewei322_1",
    "chewei70_1",
    "chewei838_1",
    "chewei162_1",
    "chewei206_1",
    "chewei482_1",
    "chewei711_1",
    "chewei358_1",
    "chewei739_1",
    "chewei227_1",
    "chewei368_1",
];

const fengjiB2 = ["fengji1_1", "fengji2_1", "fengji3_1", "fengji4_1", "fengji5_1", "fengji6_1", "fengji7_1", "fengji8_1", "fengji9_1", "fengji10_1", "fengji11_1", "fengji12_1", "fengji13_1", "fengji14_1", "fengji15_1", "fengji16_1", "fengji17_1", "fengji18_1", "fengji19_1", "fengji20_1", "fengji21_1", "fengji22_1", "fengji23_1", "fengji24_1", "fengji25_1", "fengji26_1"]
const zhaoming = ["zhaoming1_1", "zhaoming2_1", "zhaoming3_1", "zhaoming4_1", "zhaoming5_1", "zhaoming6_1", "zhaoming7_1", "zhaoming8_1", "zhaoming9_1", "zhaoming10_1", "zhaoming11_1", "zhaoming12_1", "zhaoming13_1", "zhaoming14_1", "zhaoming15_1", "zhaoming16_1", "zhaoming17_1", "zhaoming18_1", "zhaoming19_1", "zhaoming20_1", "zhaoming21_1", "zhaoming22_1", "zhaoming23_1", "zhaoming24_1", "zhaoming25_1", "zhaoming26_1", "zhaoming27_1", "zhaoming28_1", "zhaoming29_1", "zhaoming30_1", "zhaoming31_1"]
const wangguan = ["wangguan1_1", "wangguan2_1", "wangguan3_1", "wangguan4_1", "wangguan5_1"]
const chongdianzhuang = ["chongdianzhuang1_1", "chongdianzhuang2_1", "chongdianzhuang3_1", "chongdianzhuang4_1", "chongdianzhuang5_1", "chongdianzhuang6_1", "chongdianzhuang7_1", "chongdianzhuang8_1", "chongdianzhuang9_1", "chongdianzhuang10_1", "chongdianzhuang11_1", "chongdianzhuang12_1", "chongdianzhuang13_1", "chongdianzhuang14_1", "chongdianzhuang15_1", "chongdianzhuang16_1", "chongdianzhuang17_1", "chongdianzhuang18_1", "chongdianzhuang19_1", "chongdianzhuang20_1", "chongdianzhuang21_1", "chongdianzhuang22_1", "chongdianzhuang23_1", "chongdianzhuang24_1", "chongdianzhuang25_1", "chongdianzhuang26_1", "chongdianzhuang27_1", "chongdianzhuang28_1", "chongdianzhuang29_1", "chongdianzhuang30_1", "chongdianzhuang31_1", "chongdianzhuang32_1", "chongdianzhuang33_1", "chongdianzhuang34_1", "chongdianzhuang35_1", "chongdianzhuang36_1", "chongdianzhuang37_1", "chongdianzhuang38_1"]
const changdijiankong = ["changdijiankong1_1", "changdijiankong2_1", "changdijiankong3_1", "changdijiankong4_1", "changdijiankong5_1", "changdijiankong6_1", "changdijiankong7_1", "changdijiankong8_1", "changdijiankong9_1", "changdijiankong10_1", "changdijiankong11_1", "changdijiankong12_1", "changdijiankong13_1", "changdijiankong14_1", "changdijiankong15_1", "changdijiankong16_1"]
const daozhajiankong = ["daozhajiankong1_1", "daozhajiankong2_1", "daozhajiankong3_1", "daozhajiankong4_1", "daozhajiankong5_1", "daozhajiankong6_1", "daozhajiankong7_1", "daozhajiankong8_1", "daozhajiankong9_1", "daozhajiankong10_1", "daozhajiankong11_1"]
const daozhashebei = ["daozhashebei1_1", "daozhashebei2_1", "daozhashebei3_1", "daozhashebei4_1", "daozhashebei5_1", "daozhashebei6_1", "daozhashebei7_1", "daozhashebei8_1", "daozhashebei9_1", "daozhashebei10_1", "daozhashebei11_1", "daozhashebei12_1", "daozhashebei13_1"]
const dianzitinchepai = ["dianzitinchepai1_1", "dianzitinchepai2_1", "dianzitinchepai3_1", "dianzitinchepai4_1", "dianzitinchepai5_1", "dianzitinchepai6_1", "dianzitinchepai7_1", "dianzitinchepai8_1", "dianzitinchepai9_1", "dianzitinchepai10_1", "dianzitinchepai11_1", "dianzitinchepai12_1", "dianzitinchepai13_1", "dianzitinchepai14_1", "dianzitinchepai15_1", "dianzitinchepai16_1", "dianzitinchepai17_1", "dianzitinchepai18_1"]
const huanjingzhiliang = ["huanjingzhiliang1_1", "huanjingzhiliang2_1", "huanjingzhiliang3_1", "huanjingzhiliang4_1", "huanjingzhiliang5_1", "huanjingzhiliang6_1", "huanjingzhiliang7_1", "huanjingzhiliang8_1", "huanjingzhiliang9_1", "huanjingzhiliang10_1", "huanjingzhiliang11_1", "huanjingzhiliang12_1", "huanjingzhiliang13_1"]
const bianpeidian = ["bianpeidian1_1", "bianpeidian2_1", "bianpeidian3_1", "bianpeidian4_1", "bianpeidian5_1", "bianpeidian6_1", "bianpeidian7_1", "bianpeidian8_1", "bianpeidian9_1", "bianpeidian10_1", "bianpeidian11_1", "bianpeidian12_1", "bianpeidian13_1", "bianpeidian14_1", "bianpeidian15_1", "bianpeidian16_1", "bianpeidian17_1"]
const gaojing = [
    'warning1',
    'warning2',
    'warning3',
    'warning4',
    'warning5',
    'warning6',
    'warning7',
    'warning8',
    'warning9',
    'warning10',
    'warning11',
    'warning12',
    'warning13',
    'warning14',
];

// 假数据报警
const warningArray = [

    "fengji1_1", "fengji2_1", "fengji3_1", "fengji4_1",

    "zhaoming1_1", "zhaoming2_1", "zhaoming3_1", "zhaoming4_1",

    "wangguan1_1", "wangguan2_1",

    "chongdianzhuang1_1", "chongdianzhuang2_1", "chongdianzhuang3_1", "chongdianzhuang4_1",

    "changdijiankong1_1", "changdijiankong2_1", "changdijiankong3_1", "changdijiankong4_1",

    "daozhajiankong1_1", "daozhajiankong2_1", "daozhajiankong3_1", "daozhajiankong4_1",

    "daozhashebei1_1", "daozhashebei2_1", "daozhashebei3_1", "daozhashebei4_1",

    "dianzitinchepai1_1", "dianzitinchepai2_1", "dianzitinchepai3_1", "dianzitinchepai4_1",

    "huanjingzhiliang1_1", "huanjingzhiliang2_1", "huanjingzhiliang3_1", "huanjingzhiliang4_1",

    "bianpeidian1_1", "bianpeidian2_1", "bianpeidian3_1", "bianpeidian4_1",

    'warning1',

    'warning2',
];

// 假数据待机
const standbyArray = [
    'fengji26_1',
    'zhaoming31_1',
    'wangguan5_1',
    'chongdianzhuang38_1',
    'changdijiankong16_1',
    'daozhajiankong11_1',
    'daozhashebei13_1',
    'dianzitinchepai18_1',
    'huanjingzhiliang13_1',
    'bianpeidian17_1',
    'warning12',
    'warning13',
    'warning14',
]




export {
    pointB2,
    fengjiB2,
    zhaoming,
    wangguan,
    chongdianzhuang,
    changdijiankong,
    daozhajiankong,
    daozhashebei,
    dianzitinchepai,
    huanjingzhiliang,
    bianpeidian,
    warningArray,
    standbyArray,
    gaojing
};
